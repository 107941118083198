/* required
// - scr-gallery.less
// - slick.js + less
// - preload.js
 */

(function ($) {
    pushToNS('SCR.Components.ScrGallery.scrGallery', function () {
      var _self = this;
      var idLoad;

      var init = function () {
        $(document).on('click', '*[data-scr-gallery]', function () {
          var _me = $(this);
          var _thisGal = _me.attr('data-scr-gallery');
          var _string = '*[data-scr-gallery="' + _thisGal + '"]';
          var galleryItems = $(_string);
          var a = 0;
          var _galleryImagesArray = [];
          var _galleryThumbsArray = [];
          galleryItems.each(function () {
            $(this).attr('data-index', a);
            _galleryImagesArray.push($(this).attr('href'));
            _galleryThumbsArray.push($(this).attr('data-thumb'));
            a = a + 1;
          });
          var _meIndex = parseInt(_me.attr('data-index'));
          createGalleryTemplate(_galleryImagesArray, _galleryThumbsArray, _meIndex);
          $('body').addClass('scr-gallery-on');
          $('html').addClass('scr-gallery-on');
          return false;
        });
        $(window).resize(function () {
          resizeGalleryImages();
        });
      };

      var createGalleryTemplate = function (_galleryImagesArray, _galleryThumbsArray, _meIndex) {
        var topItemsTemplate = '';
        var classLarge = '';
        if (_galleryImagesArray.length <= 1) {
          classLarge = 'large';
        }

        for (var a = 0; a < _galleryImagesArray.length; a++) {
          topItemsTemplate =
            topItemsTemplate +
            '<div class="scr-gallery__top__item">' +
            '<div class="scr-gallery__preloader">' +
            '</div>' +
            '<img class="img" rel="' +
            _galleryImagesArray[a] +
            '" />' +
            '</div>';
        }
        var topItemsBottom = '';
        for (var b = 0; b < _galleryThumbsArray.length; b++) {
          topItemsBottom =
            topItemsBottom +
            '<a href="#" class="scr-gallery__bottom__item" style="background-image: url(' +
            _galleryThumbsArray[b] +
            ')">' +
            '</a>';
        }
        var template =
          '<div class="scr-gallery ' +
          classLarge +
          '">' +
          '<a href="#close" class="scr-gallery__close"></a>' +
          '<div class="scr-gallery__in">' +
          '<div class="scr-gallery__top">' +
          topItemsTemplate +
          '</div>' +
          '<div class="scr-gallery__bottom">' +
          topItemsBottom +
          '</div>' +
          '</div>' +
          '</div>';

        $('body').append(template);

        $('.scr-gallery__top').slick({
          infinite: false,
          initialSlide: _meIndex,
        });

        $('.scr-gallery__bottom__item').eq(_meIndex).addClass('active');

        $('.scr-gallery__top').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
          ////console.log(nextSlide);
          $('.scr-gallery__bottom__item').removeClass('active');
          $('.scr-gallery__bottom__item').eq(nextSlide).addClass('active');
          centerBottomLineByIndex(nextSlide);
        });

        $('.scr-gallery__close').bind('click', function () {
          $('.scr-gallery__top').slick('unslick');
          $('.scr-gallery').remove();
          $('body').removeClass('scr-gallery-on');
          $('html').removeClass('scr-gallery-on');
          return false;
        });

        $('.scr-gallery__bottom__item').bind('click', function () {
          var _me = $(this);
          var _meId = _me.index();
          $('.scr-gallery__top').slick('slickGoTo', _meId);
          return false;
        });

        preloadItem(_meIndex);
      };

      var preloadItem = function (_index) {
        idLoad = _index;
        var itemToPreload = $('.scr-gallery__top__item').eq(_index).find('.img');

        if (!itemToPreload.hasClass('loaded')) {
          images = images || {};
          var manifest = [{ src: itemToPreload.attr('rel'), id: 'image' }];
          var loader = new createjs.LoadQueue(false);
          loader.addEventListener('fileload', handleFileLoad);
          loader.addEventListener('complete', handleComplete);
          loader.loadManifest(manifest);
        }
      };

      function handleFileLoad(evt) {
        if (evt.item.type == 'image') {
          images[evt.item.id] = evt.result;
        }
      }

      function handleComplete() {
        var itemToPreload = $('.scr-gallery__top__item').eq(idLoad).find('.img');
        $('.scr-gallery__top__item').eq(idLoad).find('.scr-gallery__preloader').hide();
        itemToPreload.attr('src', itemToPreload.attr('rel'));
        itemToPreload.addClass('loaded');
        var _nextItem = idLoad + 1;
        ////console.log(_nextItem + ' ' + $('.scr-gallery__top__item').length);
        if (_nextItem >= $('.scr-gallery__top__item').length) {
          _nextItem = 0;
        }
        resizeGalleryImages();
        preloadItem(_nextItem);
      }

      var resizeGalleryImages = function () {
        $('.scr-gallery__top__item .img').each(function () {
          var _me = $(this);
          _me.removeClass('landscape');
          if (_me.width() > $(window).width()) {
            _me.addClass('landscape');
          }
          centerBottomLineByIndex($('.scr-gallery__top').slick('slickCurrentSlide'));
        });
      };

      var centerBottomLineByIndex = function (aktSlide) {
        var _bot = $('.scr-gallery__bottom');
        if (_bot.width() > $('.scr-gallery__in').width()) {
          var aktpos =
            aktSlide * $('.scr-gallery__bottom__item').eq(0).width() * -1 -
            $('.scr-gallery__bottom__item').eq(0).width() / 2;
          if (aktpos > 0 - $('.scr-gallery__in').width() / 2) {
            aktpos = 0 - $('.scr-gallery__in').width() / 2;
          } else if (aktpos < $('.scr-gallery__in').width() / 2 - _bot.width()) {
            aktpos = $('.scr-gallery__in').width() / 2 - _bot.width();
          }
          _bot.css('transform', 'translateX(' + aktpos + 'px)');
        } else {
          _bot.css('transform', 'translateX(-50%)');
        }
        setTimeout(function () {
          _bot.css('transition', ' transform 300ms ease-in-out');
        }, 100);

        //
      };

      init();
    });

})(jQuery);
