

(function ($) {pushToNS('SCR.Components.Floorplan.floorplan', function () {
  var _self = this;
  var _floorwrapper = $('.floorlegend__wrapper');

  var _ww = 1600;
  var _hh = 900;

  var init = function () {
    $(window).resize(function () {
      // resizeFX()
    });
    //resizeFX();

    $('.mapitem').each(function () {
      var _me = $(this);
      var _index = _me.index();

      // $('.slide-top-txt__slider').slick({});

      _me.bind('click', function () {
        var oldKlip = $('.slide-top-txt .slide-top-txt__item.active');
        var newKlip = $('.slide-top-txt .slide-top-txt__item').eq(_index);

        var indexOld = oldKlip.index();
        var indexNew = _index;

        $('.slide-top-txt .slide-top-txt__item:not(.active)').css('opacity', '0');

        oldKlip.removeClass('active');

        //console.log("indexes" + indexOld + ' - ' + indexNew);

        var _direction = 25;

        if (indexOld > indexNew) {
          _direction = -25;
        }

        if (indexOld !== indexNew) {
          TweenMax.to(oldKlip, 0.3, {
            alpha: 0,
            y: _direction,
            ease: Power2.easeInOut,
            onComplete: function () {
              //oldKlip.hide();
            },
          });

          newKlip.show().css('opacity', '0').addClass('active');
          TweenMax.fromTo(
            newKlip,
            0.3,
            {
              alpha: 0,
              y: _direction * -1,
            },
            {
              alpha: 1,
              y: 0,
              delay: 0.2,
              ease: Power2.easeInOut,
            }
          );

          $('.layers .layer.active').removeClass('active');
          $('.layers .layer').eq(_index).addClass('active');
        }
        return false;
      });
      _me.bind('mouseenter', function () {
        //console.log("over" + _index);
        $('.layers .layer').eq(_index).addClass('hover');
        return false;
      });
      _me.bind('mouseleave', function () {
        //console.log("out" + _index);
        $('.layers .layer').eq(_index).removeClass('hover');
        return false;
      });
    });

    $('.mapitem').eq(0).trigger('click');

    $('.slide-top-txt__slider').slick({});

    $('map').imageMapResize();
  };

  init();
});})(jQuery);