(function ($) {
  function _isMobile() {
    var isMobile = (/iphone|ipod|ipad|android|ie|blackberry|fennec/).test
      (navigator.userAgent.toLowerCase());
    //isMobile = true;
    return isMobile;
  }

  $(document).ready(function () {

    /*
    if ($('.maplocation').length > 0) {
        $('body').css('width', '1600px');
        $('html').css('overflow', 'auto');
        $('body').css('min-height', '0');
        $('html').css('min-heitht', '0');
        var $viewport = $('head meta[name="viewport"]');
        $viewport.attr('content', 'width=device-width, initial-scale=1, maximum-scale=4');
    }
    */

    initLibs();


    new SCR.Components.SameHeight.sameHeight();
    new SCR.Components.Tooltip.tooltip();
    new SCR.Components.Floorlegend.floorlegend();
    new SCR.Components.Floorplan.floorplan();
    new SCR.Components.ScrGallery.scrGallery();
    new SCR.Components.Header.pinunp();
    new SCR.Components.SectionMonitor.sectionMonitor();


    if ($('textarea').length > 0) {
      var textarea = document.getElementById('textarea');
    }
    if ($('.wrapper').length > 0) {
      var el = document.querySelector('.wrapper');
      new PinchZoom.default(el, {});
    }


    $('.gallery-slick').slick({
      infinity: false,
      dots: true
    });


    $('.form-group input, .form-group textarea').each(function () {
      $(this).on("focusin", function () {
        $(this).closest('.form-group').addClass('on');
      });
      $(this).on("focusout", function () {
        console.log($(this).val().length)
        if ($(this).val().length <= 0) {
          $(this).closest('.form-group').removeClass('on');
        }
      });
    });

    $(document).on("input", ".numericonly", function () {
      this.value = this.value.replace(/[^\d.,-\s]/g, '');
    });
    $(document).on("keypress", ".numericonly", function () {
      this.value = this.value.replace(/[^\d.,-\s]/g, '');
    });
    $(document).on("focusout", ".numericonly", function () {
      if ((this.value === '') || (this.value.length <= 0) || (this.value.length === false)) {
        this.value = '';
      } else {
        if ($(this).attr('data-max')) {
          var _max = Number($(this).attr('data-max'));
          if (Number(this.value) > _max) {
            this.value = $(this).attr('data-max');
          }
        }
        if ($(this).attr('data-min')) {
          var _min = Number($(this).attr('data-min'));
          if (Number(this.value) < _min) {
            this.value = $(this).attr('data-min');
          }
        }
      }
    });


    $('.article-slider').slick({
      dots: true,
      infinite: false,
      speed: 600,
      slidesToShow: 1,
      variableWidth: true,
      focusOnSelect: true
    });

    $('.article-map__detail, .article-map .article-map__controls').on('click', function () {


      if (!$('.article-map').hasClass('opened')) {
        $('.article-map').addClass('opened');
      } else {
        $('.article-map').removeClass('opened');
      }

      return false
    });

    $('.oepengallery').on('click', function () {
      $('.opengalerybutton').trigger('click');
    });


    $('.popupform__close').on('click', function () {
      //$('.popupform').hide();
      $('.popupform').removeClass('isshown');
      $('.detail__wraepper').show();
      return false
    });

    $('.openpopup').on('click', function () {
      //$('.popupform').show();
      $('.popupform').addClass('isshown');
      $('.detail__wraepper').hide();
      return false
    });

    $(window).resize(function () {
      checkMenusize();
    })

    $('.floorselectmt select').on('change', function (e) {
      var _val = $(this).val();
      if (_val !== '') {
        location.href = _val;
      }
      ;
    });
    checkMenusize();


    new SCR.Components.ScrollTo.scrollto();


  });

  var checkMenusize = function () {
    if ($('.header').length > 0) {
      $('.header').removeClass('short');
      var _posSocial = $('.header__social').get(0).offsetTop;
      if (_posSocial > 0) {
        $('.header').addClass('short');
      }
    }
  }

  var chkReadyState = setInterval(function () {
    if (document.readyState == "complete") {
      clearInterval(chkReadyState);
      afterLoad();
    }
  }, 100);


  var afterLoad = function () {
    var _preloader = $('.preloader');
    setTimeout(function () {
      TweenMax.to(_preloader, 1, {
        'alpha': 0, onComplete: function () {
          _preloader.addClass('hidden');
        }
      });
    }, 2000)


  };

  var initLibs = function () {
    $('body').addClass('_ready');
    if (_isMobile()) {
      $('body').addClass('mt');
    }
    $('.selectpicker').selectpicker();
    //bootstrap validator
    $('.form').validator();
    //Slick
    //$('.slick').slick();
  };

  $(window).load(function () {

  });


  
})(jQuery);
