/* required */
(function ($) {
  pushToNS('SCR.Components.SectionMonitor.sectionMonitor', function () {
    var _self = this;

    var init = function () {
      if ($('.select-active').length > 0) {
        $(window).scroll(function (event) {
          var aktScroll = $(window).scrollTop();
          var _activeSection = '#' + $('.select-active').first().attr('id');

          $('.select-active').each(function () {
            var MyOffset = $(this).offset().top;
            if (MyOffset < aktScroll + $(window).height() / 2) {
              _activeSection = '#' + $(this).first().attr('id');
            }
          });

          var _active_header = $('.scroll-to[href="' + _activeSection + '"]');
          if (!_active_header.hasClass('active')) {
            $('.scroll-to.active').removeClass('active');
            _active_header.addClass('active');
          }
        });
      }
    };

    init();
  });
})(jQuery);
