

(function ($) {pushToNS('SCR.Components.Floorlegend.floorlegend', function () {
  var _self = this;

  var init = function () {
    if ($('.floorlegend').length > 0) {
      getPositions();
      setPositions();
      $(window).resize(function () {
        reiszePos();
      });
      reiszePos();

      $('ul.floorlegend__items li a').on('click', function () {
        return false;
      });
    }
  };

  var getPositions = function () {
    $('.floorlegend').mousedown(function (event) {
      var relX = event.pageX - $(this).offset().left;
      var relY = event.pageY - $(this).offset().top;
      var relBoxCoords =
        'data-pos-x="' +
        parseFloat(relX / ($(this).width() / 100)).toFixed(2) +
        '%" data-pos-y="' +
        parseFloat(relY / ($(this).height() / 100)).toFixed(2) +
        '%"';
      //console.log(relBoxCoords);
      copyToClipboard(relBoxCoords);

      //$(".mouse-cords").text(relBoxCoords);
    });
  };

  var setPositions = function () {
    $('.floorlegend .floorlegend__items li').each(function () {
      $(this)
        .css({
          left: $(this).attr('data-pos-x'),
          top: $(this).attr('data-pos-y'),
        })
        .addClass('isvisible');
      /*
            var _px = $(this).attr('data-pos-x').replace('%');
            if (parseInt(_px) > 50) {
                $(this).addClass('toleft');
            }
            */
    });
  };

  var reiszePos = function () {
    $('.floorlegend .floorlegend__items li').each(function () {
      var _me = $(this);
      var _width = $(window).width();
      if (_me.find('.title').width() > 370) {
        _me.find('.title').addClass('lock');
      }

      if (_me.offset().left + _me.find('.title').width() > _width) {
        _me.addClass('toleft');
      } else {
        _me.removeClass('toleft');
      }
    });
  };

  function copyToClipboard(txt) {
    var $temp = $('<input>');
    $('body').append($temp);
    $temp.val(txt).select();
    document.execCommand('copy');
    $temp.remove();
  }

  init();
});})(jQuery);