/** Add custom namespace functionality.*/
pushToNS = function(path, constr) {
    var parts = path.split('.');
    var parent = window;
    var currentPart = '';
    for (var i=0; i<parts.length; i++) {
        currentPart = parts[i];
        parent[currentPart] = parent[currentPart] || {};
        if (i==(parts.length-1)) {
            parent[currentPart] = constr;
        } else {
            parent = parent[currentPart];
        }
    }
};