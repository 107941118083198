
(function ($) {
    pushToNS('SCR.Components.Header.pinunp', function () {
      var _self = this;
      var _header = $('.header-mt');

      var init = function () {
        scrollDirection();

        $('*[data-menu="open"]').on('click', function () {
          if (!$('.header-mt-list').hasClass('opened')) {
            $('.header-mt-list').addClass('opened');
            $('.header-mt').addClass('opened');

            $('html, body').addClass('no-scroll');
          } else {
            $('.header-mt-list').removeClass('opened');
            $('.header-mt').removeClass('opened');
            $('html, body').removeClass('no-scroll');
          }
          return false;
        });

        $('*[data-menu="close"]').on('click', function () {
          $('.header-mt-list').removeClass('opened');
          $('html, body').removeClass('no-scroll');
          return false;
        });

        $('.lang__select select, .lang__select__mt').change(function () {
          var id = $(this).prop('selectedIndex');
          var data__val = $(this).find('option').eq(id).attr('data-href');
          //alert(data__val);
          window.location.href = data__val;
        });
      };

      var scrollDirection = function () {
        var lastScrollTop = 0;
        $(window).on('scroll', function () {
          if ($(window).height() < $('.layout').height()) {
            st = $(this).scrollTop();
            if (st <= 0) {
              st = 0;
            }
            if (st < lastScrollTop) {
              _header.addClass('up');
              _header.removeClass('down');
            } else {
              _header.addClass('down');
              _header.removeClass('up');
            }
            lastScrollTop = st;

            if (st <= 0) {
              $('.header').removeClass('at_bottom');
              _header.addClass('up');
              _header.removeClass('down');
            }
          }
        });
      };

      init();
    });

})(jQuery);