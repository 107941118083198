(function ($) {
  pushToNS('SCR.Components.Tooltip.tooltip', function () {
    var _self = this;
    var containers = $('*[data-tooltip]');
    var containersId = '*[data-tooltip]';
    var initialized = false;
    var hoverTween;
    var pad = 15;

    var init = function () {
      if (initialized == false) {
        initialized = true;
        addEvents();
      }
    };

    var addEvents = function () {
      $(document).on('mouseover touchstart', containersId, function (e) {
        closeAll();
        $(this).addClass('tooltip-on');
        TweenMax.killTweensOf($(this).find('.tooltip-trv'));
        $(this).find('.tooltip-trv').remove();

        var _me = $(this);
        var divPos = {};

        var offset = _me.offset();
        divPos = {
          left: Math.round(_me.width() / 2),
          top: offset.top,
        };
        var dataText = _me.attr('data-tooltip');

        var newY_ = offset.top;
        var newX_ = offset.left;

        var _direction = _me.attr('data-tooltip-direction');

        $('body').append('<div class="tooltip-trv">' + dataText + '<div class="tooltip__icoArrow"></div></div>');
        var _tt = $('.tooltip-trv');

        var _new_left = 0;
        var _new_top = 0;
        var arrow = 'top';

        if (_direction === 'right') {
          _new_left = newX_ + _me.width() + pad;
          _new_top = newY_ + _me.height() / 2 - _tt.outerHeight() / 2;
          arrow = 'left';
        } else if (_direction === 'left') {
          _new_left = newX_ - _tt.outerWidth() - pad;
          _new_top = newY_ + _me.height() / 2 - _tt.outerHeight() / 2;
          arrow = 'right';
        } else if (_direction === 'bottom') {
          _new_left = newX_ - _tt.outerWidth() / 2 + _me.width() / 2;
          _new_top = newY_ + _me.height() + pad;
          arrow = 'top';
        } else {
          _new_left = newX_ - _tt.outerWidth() / 2 + _me.width() / 2;
          _new_top = newY_ - _tt.outerHeight() - pad;
          arrow = 'bottom';
        }

        var useAutoHeight = false;

        if (_new_left < 0) {
          _new_left = 0;
          useAutoHeight = true;
        } else if (_new_left > $(window).width() - _tt.outerWidth()) {
          _new_left = $(window).width() - _tt.outerWidth();
          useAutoHeight = true;
        }

        if (useAutoHeight == true) {
          if (newY_ > $(window).height() / 2 + $(window).scrollTop()) {
            _new_top = newY_ - _tt.outerHeight() - pad;
            arrow = 'bottom';
          } else {
            _new_top = newY_ + _me.height() + pad;
            arrow = 'top';
          }
        }

        _tt.css({
          left: _new_left + 'px',
          top: _new_top + 'px',
        });

        var _ta_left = 0;
        var _ta_top = 0;

        if (arrow === 'left') {
          _ta_left = 0;
          _ta_top = _tt.outerHeight() / 2;
        } else if (arrow === 'right') {
          _ta_left = _tt.outerWidth();
          _ta_top = _tt.outerHeight() / 2;
        } else if (arrow === 'top') {
          _ta_top = 0;
          _ta_left = offset.left - _new_left + _me.width() / 2;

          if (_ta_left < 15) {
            _ta_left = 15;
          } else if (_ta_left > _tt.outerWidth() - 15) {
            _ta_left = _tt.outerWidth() - 15;
          }
        } else if (arrow === 'bottom') {
          _ta_top = _tt.outerHeight();
          _ta_left = offset.left - _new_left + _me.width() / 2;

          if (_ta_left < 15) {
            _ta_left = 15;
          } else if (_ta_left > _tt.outerWidth() - 15) {
            _ta_left = _tt.outerWidth() - 15;
          }
        }

        $('.tooltip__icoArrow').css({
          left: _ta_left + 'px',
          top: _ta_top + 'px',
        });
        return false;
      });

      $(document).on('touchstart', function (e) {
        if ($('.tooltip-on').length > 0) {
          closeAll();
        }
      });

      $(document).on('mouseout', containersId, function (e) {
        closeAll();
      });
    };

    var closeAll = function () {
      $('.tooltip-on').removeClass('tooltip-on');
      clearTimeout(hoverTween);
      var tooltip = $('.tooltip-trv');
      TweenMax.to(tooltip, 0.2, {
        alpha: 0,
        onComplete: function () {
          tooltip.remove();
        },
      });
    };

    init();
  });
})(jQuery);
