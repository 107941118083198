(function ($) {
  $(".header-mt-scr .scroll-to").click(function () {
    $(".mt-open").click()
  });
  $(".chosen-single").click(function () {
    $(".result-selected").hide()
  });
  $(".webform-component-boolean").append("<span></span>");
  $(".webform-component-boolean span").click(function () {
    if ($(this).parent().hasClass("webform-component--gdpr")) {
      var checked = $(".webform-component--gdpr input");
      checked.prop("checked", !checked.prop("checked"));
    } else if ($(this).parent().hasClass("webform-component--confirm")) {
      var checked = $(".webform-component--confirm input");
      checked.prop("checked", !checked.prop("checked"));
    } else {
      var checked = $(".webform-component--newsletter input");
      checked.prop("checked", !checked.prop("checked"));
    }
  });
  $("#webform-client-form-22, #webform-client-form-21").on("submit", function (e) {
    if (!$("#edit-submitted-confirm").is(":checked") && !$("#edit-submitted-gdpr").is(":checked")) {
      e.preventDefault();
      if ($("#webform-client-form-22").length > 0) {
        var text_err = 'Pole "Súhlasím so spracovaním osobných údajov na účely kontaktovania" je povinné.';
      } else {
        var text_err = 'Field "I agree to the processing of personal data for the purpose of contacting" is required.';
      }
      $('.webform-component--confirm, .webform-component--gdpr').after('<div class="err-message" style="clear:both; font-weight: bold; font-size: 100%; color: red; line-height: 16px; text-align: left; padding-left: 36px;">' + text_err + '</div>');
    }
  });
  if ($("body").hasClass("i18n-sk")) {
    $(".g-recaptcha").after('<div class="required_text"><span class="form-required">*</span> Povinné polia</div>');
  } else if ($("body").hasClass("i18n-en")) {
    $(".g-recaptcha").after('<div class="required_text"><span class="form-required">*</span> Required fields</div>');
  }
  $(".popupform__close").click(function () {
    $(this).hide();
  });
  $(".not-front .button__send").click(function () {
    $(".popupform__close").show();
  });
  if ($("textarea").is(":focus")) {
    $(this).attr("style", "");
  }
  ;
  $(document).on('focus', ':input', function () {
    $(this).attr('autocomplete', 'new-password');
  });
  /*$("textarea").on("keyup", function() {
      $(this).removeAttr("style");
      });*/
  $(".chosen-single div").remove();
  /*$(".chosen-single").append("<div></div>");
 document.styleSheets[0].addRule('.chosen-single div:before', 'content: "c";font-family: Panorama;font-size:12px;position:absolute;right:22px;');*/
  /*smooth scroll*/
  $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').click(function (event) {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        event.preventDefault();
        $('html, body').animate({ scrollTop: target.offset().top }, 1000, function () {
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) {
            return false;
          } else {
            $target.attr('tabindex', '-1');
            $target.focus();
          }
          ;
        });
      }
    }
  });
  /*smooth scroll end*/
})(jQuery);
