(function ($) {
  pushToNS('SCR.Components.ScrollTo.scrollto', function () {
    var init = function () {
      $('.scroll-to').bind('click.smoothscroll', function (e) {
        if ($('.header-mt-list').hasClass('opened')) {
          $('.header-mt-list').removeClass('opened');
          $('.header-mt').removeClass('opened');
          $('html, body').removeClass('no-scroll');
        }

        e.preventDefault();
        var target = this.hash,
          $target = $(target);

        if ($target.length) {
          $('html, body')
            .stop()
            .animate(
              {
                scrollTop: $target.offset().top - 40,
              },
              600,
              'swing',
              function () {
                window.location.hash = target;
              }
            );
        }
      });

      var target = window.location.hash,
        $target = $(target);
      if ($target.length) {
        $('html, body')
          .stop()
          .animate(
            {
              scrollTop: $target.offset().top - 40,
            },
            0,
            'swing',
            function () {
              window.location.hash = target;
            }
          );
      }
    };

    init();
  });
})(jQuery);
