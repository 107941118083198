(function ($) {
    pushToNS('SCR.Components.SameHeight.sameHeight', function () {
      var _self = this;

      var init = function () {
        resizeFx();
        $(window).resize(function () {
          resizeFx();
        });
      };

      var resizeFx = function () {
        $('.same-height').each(function () {
          var _cd = $(this);

          var array_indexes = [];
          var array_heights = [];

          _cd.find('[data-sameheight-id]').css('height', 'auto');

          _cd.find('[data-sameheight-id]').each(function () {
            var _myId = $(this).attr('data-sameheight-id');
            var myHeight = $(this).outerHeight();

            //console.log(array_indexes.indexOf(_myId));

            if (array_indexes.indexOf(_myId) < 0) {
              array_indexes.push(_myId);
              array_heights.push(myHeight);
            } else {
              var index = array_indexes.indexOf(_myId);
              if (myHeight > array_heights[index]) {
                array_heights[index] = myHeight;
              }
            }
          });

          for (var a = 0; a < array_indexes.length; a++) {
            _cd.find('*[data-sameheight-id=' + array_indexes[a] + ']').css('height', array_heights[a] + 'px');
            //console.log('id = '+ array_indexes[a])
          }

          $('.js-mg-headline').each(function () {
            var _mgUp = array_heights[0];
            //console.log('_mgUp = '+_mgUp);
            $(this)
              .find('.txt')
              .css('margin-top', '-' + _mgUp + 'px');
          });
        });
      };

      init();
    });
})(jQuery);